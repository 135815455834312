import React from "react"
import HeroImage from "../../../images/graduating-student.jpg"
import AboutImage from "../../../images/students.jpg"
import StepOne from "../../../images/exhibitB.jpg"
import StepTwo from "../../../images/dab.png"
import StepThree from "../../../images/book-reading.jpg"
import PiggySVG from "../../../images/piggy.svg"
import ReceiveSVG from "../../../images/receive.svg"
import ScaleSVG from "../../../images/scale.svg"
import GradSVG from "../../../images/grad.svg"
import TrendingUpIcon from "../../../images/funds-fill.svg"
import VideoImage from "../../../images/video-image.jpg"
import AccessLogo from "../../../images/access-logo.png"
import CredpalLogo from "../../../images/credpal-logo.svg"
import Tomi from "../../../images/tomi-ogedengbe.jpeg"
import Kazeem from "../../../images/kazeem-ajani.jpg"
import Femi from "../../../images/femi-shonubi.jpg"
import Onyedikachi from "../../../images/onyedikachi.jpg"
import Adeyanju from "../../../images/Adeyanju.jpg"
import Omokorede from "../../../images/Omokorede.jpg"
import Omoyeni from "../../../images/Omoyeni.jpeg"
import Johnson from "../../../images/Johnson.png"
import Oluwakemi from "../../../images/Oluwakemi.jpg"
import PartnerImage from "../../../images/partner.jpg"

import SchorlashipIcon from "@material-ui/icons/School"
import SavingsIcon from "@material-ui/icons/SaveAlt"
import LoanIcon from "@material-ui/icons/BusinessCenter"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const data = {
  hero: {
    title: `Quick Access to Affordable Education Finance`,
    body: `We provide access to educational loans with no collateral relatively low interest rates.`,
    imageUrl: HeroImage,
  },
  about: {
    title: `Secure educational loans Easily`,
    body: `EduCollect provides access to Educational loans without collateral and relatively low interest rates. We allow students to secure educational loans, to enable them have access to quality education. This aligns with our vision to bridge the gap between demand for and access to quality education through the process of decentralizing the traditional method of learning.
        `,
    imageUrl: AboutImage,
  },
  video: {
    videoUrl: `https://www.youtube.com/embed/G7cEUAapW50`,
    imageUrl: VideoImage,
  },
  why: {
    title: `Why EduCollect`,
    reasons: [
      {
        icon: ScaleSVG,
        title: `No collateral required`,
      },
      {
        icon: ReceiveSVG,
        title: `Flexible repayment options`,
      },
      {
        icon: PiggySVG,
        title: `Multiple loan providers to pick from`,
      },
      {
        icon: GradSVG,
        title: `Payment is made directly to your school`,
      },
    ],
  },
  whoFor: [
    {
      title: `For Academic Purposes`,
      body: `Whether you are a parent trying to pay for your child’s school expenses or a working student trying to make ends meet, EduCollect ensures you have the financial backing to access quality education regardless of your level. From tuition to other academic-related expenses, we have got you covered. `,
    },
    {
      title: `For Professional Trainings`,
      body: `Striving to further your personal & professional development shouldn’t be limited by financial resources, so we’re here to bridge that gap for you by offering you collateral-free financing to keep your dream alive.`,
    },
    {
      title: `For Educational Establishments`,
      body: `We understand the financial strain that comes with being an Institution of learning due to the numerous processes and projects that need to be executed smoothly. With EduCollect, you can access quick loans to cover salaries, special projects and much more.`,
    },
  ],
  steps: {
    title: `We Have Got You Covered`,
    body: `Getting a loan with us is as simple as 1, 2, 3`,
    images: [StepOne, StepTwo, StepThree],
    steps: [
      {
        title: `Step 1`,
        body: `Sign up to create an account or sign in if you already have an account`,
      },
      {
        title: `Step 2`,
        body: `Fill out the application form, upload relevant documents, submit & await feedback from our financial partner`,
      },
      {
        title: `Step 3`,
        body: `Upon loan approval, payment is made to the specified account and you can continue to enjoy uninterrupted education!`,
      },
    ],
  },
  services: {
    serviceTitle: `Our Services`,
    body: `Our Services are geared towards removing financial barriers to your education by providing easy & affordable access to funds and adequate support through your learning journey.`,
    services: [
      {
        title: `Loans`,
        icon: <LoanIcon />,
      },
      {
        title: `Scholarships`,
        icon: <SchorlashipIcon />,
      },
      {
        title: `Savings`,
        icon: <SavingsIcon />,
      },
    ],
  },
  loans: [
    {
      name: `CredPal Loan`,
      url: `/application`,
      logoUrl: CredpalLogo,
      color: `rgb(17, 122, 243)`,
      eligibility: [
        `Enrolled in a school we support`,
        `You or your sponsor has a steady Income`,
      ],
      requirements: [
        `Recent 6 months bank statement of your salary account`,
        `Valid Government issued Identification card`,
        `Workplace identification`,
      ],
      charges: [`5% per month`, `No Extra charge`],
    },
    {
      name: `Access Bank Loan`,
      url: undefined,
      logoUrl: AccessLogo,
      color: `#ffff`,
      eligibility: [
        `Enrolled in a school we support`,
        `You or your sponsor has a steady Income`,
      ],
      requirements: [`A salary account with Access Bank`],
      charges: [
        `Interest Rate: 1.25% per month`,
        `Management Fee: 1% flat`,
        `Credit Life Insurance: 0.15% flat`,
      ],
    },
  ],
  testimonials: [
    {
      quote: `Going to a university and being exposed to different ways of thinking is transformative. It’s really shaped how I think about things.`,
      name: `Mr Samson Adeyemi, Lawyer at Binance Group`,
    },
    {
      quote: `I heard about EduCollect from a close friend of mine who has also been a beneficiary. And since I secured a loan from them, it has given me peace of mind to continue my studies, because I have divided my repayment into months when I get my salary. That way, the stress of one-time payment is over.`,
      name: `Julius Chibuike (MBA Student)`,
    },
    {
      quote: `As a working Nurse and mother, paying my tuition at once was a great challenge because there were other bills that needed to be sorted in the home. With EduCollect, I was able to split my tuition payment into months to enable me to continue my education and take care of certain needs at home.`,
      name: `Jadesola Adeyemi (Nursing Practitioner) `,
    },
  ],
  faq: {
    title: `Frequently Asked Questions`,
    QandA: [
      {
        question: `What is EduCollect?`,
        answer: `EduCollect is a platform that aims to solve the problem of access to finance, which is a major barrier to quality education. 
                We aggregate multiple funders and partner with Education institutions to provide finance & value services to Students, Parents, and Education service providers across various academic institutions in Africa.`,
      },
      {
        question: `How can I get the loan?`,
        answer: (
          <div>
            <p>Applying for a loan is simple:</p>
            <p>
              <em>Option 1</em>
            </p>
            <ul>
              <li>
                Sign up and complete the application form on our website and
                await feedback from our financial partner
              </li>
            </ul>
            <p>
              <em>Option 2</em>
            </p>
            <ul>
              <li>
                Apply from your Institution’s registration portal (for
                applicants from our Partners)
              </li>
            </ul>
          </div>
        ),
      },
      {
        question: `Who can access the loans?`,
        answer: `This is available to Students, Parents, and Education service providers across various academic institutions.`,
      },
      {
        question: `What are the eligibility requirements?`,
        answer: `Whether you are a working class or a business owner, you are easily eligible for this loan if you (or your sponsor) have a steady income and can afford to repay the loan.`,
      },
      {
        question: `What documentation is required to apply for the loan?`,
        answer: (
          <div>
            <p>
              <em>For the self-financed option:</em>
            </p>
            <ul>
              <li>
                Recent Six (6) months'; bank statement of your salary account
              </li>
              <li>Valid Government &ndash; issued identification card.</li>
              <li>Workplace/ company's Identification card.</li>
            </ul>
            <p>
              <em>For the guardian financed option:</em>
            </p>
            <ul>
              <li>
                Guardian's Recent Six (6) months'; bank statement of your salary
                account
              </li>
              <li>
                Guardian's Valid Government &ndash; issued identification card.
              </li>
              <li>Guardian's Workplace/ company's Identification card.</li>
            </ul>
          </div>
        ),
      },
      {
        question: `Why is my account statement required? `,
        answer: `Account statements are required to assess your spending pattern and ascertain your repayment capability.`,
      },
      {
        question: `What are the loan financing options?`,
        answer: `The loan can either be self-financed or guardian financed. In the case of guardian financing, you will need to provide details of a guarantor who will be responsible for your loan repayments. In essence, your guarantor will be taking the loan on your behalf.`,
      },
      {
        question: `Why should I take the loan?`,
        answer: `Taking advantage of the loan facility puts you in a great position to secure your education by not having to worry about making bulk tuition payments. By splitting payments, you can rest assured that you can focus on completing your studies in good time & not be held back by finances.`,
      },
      {
        question: `How long does the loan application process take?`,
        answer: `The application process itself can be completed in less than10 minutes, while the assessment process can take up to 48 hours.`,
      },
      {
        question: `What's the minimum amount I can take?`,
        answer: `The minimum loan amount is N50,000.`,
      },
      {
        question: `What's the maximum amount I can take?`,
        answer: `The maximum loan amount is N300,000`,
      },
      {
        question: `How long do I have to pay back?`,
        answer: `Since the loan is being taken to cover expenses for a semester, the loan tenor is 4 months, which gives you enough time to finish making your payments before the next semester.`,
      },
      {
        question: `How often do I have to make repayments?`,
        answer: `Loan repayments will occur every month based on the date you select after securing the loan.`,
      },
      {
        question: `What is the interest rate?`,
        answer: `5%.`,
      },
      {
        question: `What is the penalty for defaulting?`,
        answer: `We encourage you to make your loan repayments on time because late or missed payments would hurt your credit reputation and affect your ability to get credit in the future. While we hope we never have to do so, we reserve the right to refer delinquent accounts to repossession agencies. This means in case of default; Our Partners will go after defaulters with the full extent of the law. For any questions about this, or if you are unable to pay your balance, please contact us before your repayment date.`,
      },
      {
        question: `Why was my loan declined?`,
        answer: (
          <div>
            <p>
              There are so many reasons why your loan request can be declined,
              ranging from:
            </p>
            <ul>
              <li>Inconsistent or inaccurate customer information</li>
              <li>Didn't meet the basic requirements</li>
              <li>High loan exposure</li>
            </ul>
          </div>
        ),
      },
      {
        question: `How do I repay my loans (on CredPal)?`,
        answer: (
          <div>
            <p>
              Cheque - You can pay for your loans by issuing us post-dated chute
              on delivery. The cheque should be dated to be collected on dates
              of your repayment or earlier.
            </p>
            <p>
              Debit Card - You can set up your debit card for repayment via our
              mobile app. This can be done with the pay now option or the add
              card option
            </p>
            <p>
              Bank Transfer - You can do a direct transfer into our account and
              inform us about your payment. We will then confirm and acknowledge
              your payment via mail.
            </p>
            <p>
              Dashboard - You also have the convenience to make repayments from
              your CredPal dashboard.
            </p>
          </div>
        ),
      },
      {
        question: `Where do I see my repayment details?`,
        answer: (
          <div>
            <ul>
              <li>In your dashboard</li>
              <li>In your email</li>
            </ul>
            <p>
              We always show the total amount of interest you'll pay, and we
              never charge more beyond that, aside late repayment penalty
              charges. This way, you always know what you're getting into.
            </p>
          </div>
        ),
      },
      {
        question: `Will you send me repayment reminders?`,
        answer: `Yes, our finance partner will send you emails and SMS to remind you of upcoming payments.`,
      },
      {
        question: `When are my bills due?`,
        answer: `Your first monthly payment will be due 30 days from the date your loan is processed. We will send you reminders to confirm all of your due dates.`,
      },
      {
        question: `What if I need more time to pay my bill?`,
        answer: `We advise you pay your loan as soon as possible as delayed payments might attract a late fee penalty`,
      },
      {
        question: `Are there any penalties for paying off my loan early?`,
        answer: `No. On the contrary we advise you pay up your loan early, as this allows you access unique incentives based on our discretion.`,
      },
      {
        question: `What should I do if am having issues repaying my loan?`,
        answer: (
          <div>
            <p>
              If you encounter any problems repaying your loan, please contact
              our customer support at{" "}
              <a href="mailto:hello@credpal.com">hello@credpal.com</a> or{" "}
              <a href="mailto:support@educollectfinance.com">
                support@educollectfinance.com
              </a>
            </p>
          </div>
        ),
      },
    ],
  },
  team: [
    {
      name: "Femi Shonubi",
      title: "General Manager",
      image: Femi,
    },
    {
      name: "Kassim Ajani",
      title: "Operations Manager",
      image: Kazeem,
    },
    {
      name: "Tomi Ogedengbe",
      title: "Finance Manager",
      image: Tomi,
    },
    {
      name: "Onyedikachi Oyigah",
      title: "Lead Business Analyst",
      image: Onyedikachi,
    },
    {
      name: "Adeyanju Lawal",
      title: "Product Manager",
      image: Adeyanju,
    },
    {
      name: "Omokorede Amund",
      title: "Marketing & Commnications Lead",
      image: Omokorede,
    },
    {
      name: "Omoyeni Oguntade",
      title: "Customer Success Lead",
      image: Omoyeni,
    },
    {
      name: "Johnson Adekundle",
      title: "Enterprise Architect",
      image: Johnson,
    },
    {
      name: "Oluwakemi Elega",
      title: "Associate Business Analyst",
      image: Oluwakemi,
    },
  ],
  partner: {
    whyPartner: {
      image: PartnerImage,
      name: "Partner With Us",
      details: [
        {
          icon: TrendingUpIcon,
          title: "Achieve your revenue targets",
          text:
            "With access to education loans, parents and students can pay school fees at the beginning of academic terms/sessions. So you don’t have to worry about late payments or dropouts due to financial constraints.",
        },
        {
          icon: TrendingUpIcon,
          title: "Grow your Business",
          text:
            "In addition to providing access to loans for students' fees, we offer access to salary advances for teachers, and additional financing to the schools as an entity for operational expenses or development projects.",
        },
        {
          icon: <CheckCircleIcon />,
          title: "Enhance your Brand",
          text:
            "Our loan gateway is customizable and enables white-label option to increase brand visibility for Partners who have student portals. You can also redirect your students to the Educollect website for direct access to loans.",
        },
        {
          icon: <CheckCircleIcon />,
          title: "Join a community Of Education Advocates",
          text:
            "Partnering with us gives you access to other players in the education landscape, who are transforming and addressing various needs in the education industry. ",
        },
      ],
    },
    process: [
        {
            title: "Indicate Interest",
            text: "via Email: support@educollect.com or phone: +234 814 3906 442"
        },
        {
            title: "Agreement & Commercial Terms",
            text: "Review & accept terms and conditions where applicable."
        },
        {
            title: "Technical Integrations",
            text: "Deploy loan gateway on your student portal (if available)."
        },
        {
            title: "Testing & Validation",
            text: "Carry out a thorough test to ascertain that everything is fine."
        },
        {
            title: "Go Live",
            text: "Your students can start accessing loans."
        }
       
    ]
  },
}

export default data
